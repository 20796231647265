body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rdt_TableCol{
  background-color:rgb(236, 236, 236) !important;
}
.rdt_TableBody .rdt_TableRow:hover{
  background-color:rgb(247, 247, 247) !important;
  cursor:pointer;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.247); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
.spinner{
  position: fixed;
  top: 40%;
  right: 50%;

}
.hover:hover{
  color: #0d6efd;
  
}
.hover{
  text-decoration: underline;
}